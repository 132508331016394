import { ImageSkeleton, RectangleSkeleton } from '@fe-common-ui/Skeleton';
import NewsTagBarSkeleton from '@fe-news/components/molecules/NewsTagBar/NewsTagBarSkeleton';
import { styled } from '@linaria/react';
import { memo } from 'react';

const View = styled.div<{ height?: number | string | undefined }>`
  width: 100%;
  height: ${({ height }) => typeof height === 'string' ? height : `${height}px` || '120px'};
  padding: 16px 0;
  background-color: white;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e8f1;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

type NewsListItemSkeletonProps = {
  height?: number | string | undefined;
  isHaveImage?: boolean;
};

const NewsListItemSkeleton = ({ height, isHaveImage = true }: NewsListItemSkeletonProps) => {
  return (
    <View height={height}>
      <Container>
        <RectangleSkeleton width="20%" height={16} />
        <RectangleSkeleton width="100%" height={24} />
        <RectangleSkeleton width="100%" height={18} />
        <NewsTagBarSkeleton />
      </Container>
      {isHaveImage && <ImageSkeleton width={90} height={64} />}
    </View>
  );
};

export default memo(NewsListItemSkeleton);
