import { RectangleSkeleton } from '@fe-common-ui/Skeleton';
import { uuidv4 } from '@fe-common-utils/libs/uuid';
import { styled } from '@linaria/react';
import { memo } from 'react';

const View = styled.div<{ height?: number | string | undefined }>`
  width: 100%;
  height: ${({ height }) => (typeof height === 'string' ? height : `${height}px` || '26px')};
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

type NewsTagListSkeletonProps = {
  height?: number | string | undefined;
};

const DEFAULT_COUNT = 5;

const tags: number[] = Array.from({ length: DEFAULT_COUNT }).fill(0) as number[];

const NewsTagBarSkeleton = ({ height = 26 }: NewsTagListSkeletonProps) => {
  return (
    <View height={height}>
      {tags.map(_ => (
        <RectangleSkeleton key={`news_list_item_tag_skeleton_${uuidv4()}`} width={68} height='100%' />
      ))}
    </View>
  );
};

export default memo(NewsTagBarSkeleton);
