'use client';

import { AdSlot } from '@fe-cnyes/fe-dfp';
import DesktopAdProfiles from '@fe-news/constants/DFP/DesktopAdProfiles';
import { styled } from '@linaria/react';
import { useEffect, useRef } from 'react';

const DESKTOP_ADS = [
  DesktopAdProfiles.newslistNativeList1,
  DesktopAdProfiles.newslistNativeList2,
  DesktopAdProfiles.newslistNativeList3,
  DesktopAdProfiles.newslistNativeList4,
  DesktopAdProfiles.newslistNativeList5,
  DesktopAdProfiles.newslistNativeList6
];

const View = styled.div`
  height: 113px;
  border-bottom: 1px solid #e2e8f1;
  margin-top: 15px;
`;

type DesktopAdCardProps = {
  index: number;
  style: React.CSSProperties;
  onChangeCurrentVisibleAd: (v: number) => void;
  visibleAds: number[];
};
export const DesktopAdCard = ({ index, style, visibleAds, onChangeCurrentVisibleAd }: DesktopAdCardProps) => {
  const pos = index % DESKTOP_ADS.length;
  const profile = DESKTOP_ADS[pos];
  const reference = useRef<HTMLDivElement | null>(null);
  const isAdVisible = visibleAds?.includes(index);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const isVisible = entry.isIntersecting;
        if (isVisible) {
          onChangeCurrentVisibleAd(index);
        }
      },
      {
        root: null,
        rootMargin: '115px',
        threshold: 0
      }
    );

    if (reference.current) {
      observer.observe(reference.current);
    }

    // Clean up the observer
    return () => {
      if (reference.current) {
        observer.unobserve(reference.current);
      }
    };
  }, []);

  const visibleStyle = isAdVisible ? {} : { height: 0, margin: 0, borderWidth: 0 };

  return (
    <View id={`desktop-ad-card-${index}`} ref={reference} style={{ ...style, ...visibleStyle }}>
      {/* <span style={{color: 'red'}}>{index}</span> */}
      {isAdVisible && <AdSlot profile={profile} />}
    </View>
  );
};
