'use client';

import { AdSlot } from '@fe-cnyes/fe-dfp';
import {
  AsideTableSkeleton,
  News24hSkeleton,
  NewsPopularSkeleton,
  SkeletonAnueService
} from '@fe-common-ui/LoadingSkeleton';
import NewsFeatureReportSkeleton from '@fe-common-ui/LoadingSkeleton/theme/NewsFeatureReport';
import ChartQ from '@fe-news/components/IndexMobileWrapper/ChartQ/ChartQ';
import LazyLoadComponent from '@fe-news/components/LazyLoadComponent/LazyLoadComponent';
import LogoWallCarouselSkeleton from '@fe-news/components/LogoWallCarousel/LogoWallCarouselSkeleton';
import MobileAdProfiles from '@fe-news/constants/DFP/MobileAdProfiles';
import { NewsCategory } from '@fe-news/constants/categories';
import { styled } from '@linaria/react';
import dynamic from 'next/dynamic';
import { memo } from 'react';
import ReactLoadingSkeletion from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AsidePopTopicSkeleton from '@fe-news/components/molecules/AsidePopTopic/AsidePopTopicSkeleton';

const News24h = dynamic(() => import('@fe-news/components/Aside/News24h/News24h'), {
  loading: () => <News24hSkeleton />,
  ssr: false
});

const Hao = dynamic(() => import('@fe-news/components/NewsDetail/Hao'), {
  loading: () => null,
  ssr: false
});

const NewsPopular = dynamic(() => import('@fe-news/components/molecules/AsideNewsPopular/AsideNewsPopular'), {
  loading: () => <NewsPopularSkeleton />,
  ssr: false
});

const MarketFocus = dynamic(() => import('@fe-news/components/IndexMobileWrapper/MarketFocus/MarketFocus'), {
  loading: () => <News24hSkeleton />,
  ssr: false
});

const LogoWallCarousel = dynamic(() => import('@fe-news/components/LogoWallCarousel/LogoWallCarousel'), {
  loading: () => <LogoWallCarouselSkeleton />,
  ssr: false
});

const InvestCrytpoMobile = dynamic(() => import('@fe-news/components/InvestCrytpo/InvestCrytpoMobile'), {
  loading: () => <ReactLoadingSkeletion height={640} />,
  ssr: false
});

const AnueService = dynamic(() => import('@fe-news/components/AnueService/AnueService'), {
  loading: () => <SkeletonAnueService />,
  ssr: false
});

const MarketClosed = dynamic(() => import('@fe-news/components/Aside/MarketClosed/MarketClosed'), {
  loading: () => <ReactLoadingSkeletion height={270} />,
  ssr: false
});

const NewsFeatureReport = dynamic(() => import('@fe-news/components/Aside/NewsFeatureReport/NewsFeatureReport'), {
  loading: () => <NewsFeatureReportSkeleton />,
  ssr: false
});

const AsidePopTopic = dynamic(() => import('@fe-news/components/molecules/AsidePopTopic'), {
  loading: () => <AsidePopTopicSkeleton />,
  ssr: false
});

const HaoWrapper = styled.div`
  .hao-posts {
    margin-bottom: 32px;
  }
`;

const FeatureReportWrapper = styled.div`
  .section-aside-container {
    margin-bottom: 32px !important;
  }
`;

const MobileRootWrapper = ({
  category,
  serverComponents = []
}: {
  category: NewsCategory;
  serverComponents?: React.ReactNode[];
}) => {
  // 讓server component 可以傳進來並可以順序做調整
  const [NewsLatestComponent] = serverComponents;

  return (
    <>
      {/* 即時頭條 */}
      {NewsLatestComponent}
      {/* ChartQ */}
      <ChartQ category={category} />
      <AdSlot profile={MobileAdProfiles.indexBannerNews24h} />
      {/* 人氣排行 */}
      <LazyLoadComponent PlaceholderComponent={<NewsPopularSkeleton />} id='NewsPopular'>
        <NewsPopular />
      </LazyLoadComponent>
      {/* 鉅亨號 */}
      <LazyLoadComponent PlaceholderComponent={<AsideTableSkeleton height={398} />} id='hao'>
        <HaoWrapper>
          <Hao isMobile />
        </HaoWrapper>
      </LazyLoadComponent>
      <AdSlot profile={MobileAdProfiles.indexBannerTopTopics} />
      {/* 主編精選 */}
      <News24h />
      {/* 熱門時事 */}
      <LazyLoadComponent id='PopTopic' exposureRate={0.1} PlaceholderComponent={<AsidePopTopicSkeleton />}>
        <AsidePopTopic />
      </LazyLoadComponent>
      {/* 市場焦點 */}
      <LazyLoadComponent PlaceholderComponent={<AsideTableSkeleton height={420} />} id='MarketFocus'>
        <MarketFocus />
      </LazyLoadComponent>
      {/* 主題排行 */}
      {/* <LazyLoadComponent PlaceholderComponent={<AsideTableSkeleton height={420} />} id='ConceptStocksRanking'>
        <ConceptStocksRanking />
      </LazyLoadComponent> */}
      {/* 自選股票 */}
      {/* <LazyLoadComponent PlaceholderComponent={<AsideTableSkeleton height={334} />} id='FollowStocks'>
        <FollowStocks />
      </LazyLoadComponent> */}
      {/* 熱門股票 */}
      {/* <LazyLoadComponent PlaceholderComponent={<AsideTableSkeleton height={469} />} id='HotStocks'>
        <HotStocks category={category} />
      </LazyLoadComponent> */}
      {/* 下單中心 */}
      <LazyLoadComponent PlaceholderComponent={<AsideTableSkeleton height={252} />}>
        <LogoWallCarousel height={232} />
      </LazyLoadComponent>
      {/* 基金績效排行 */}
      {/* <LazyLoadComponent PlaceholderComponent={<AsideTableSkeleton height={608} />}>
        <FundRank />
      </LazyLoadComponent> */}
      {/* 路透即時外匯 */}
      {/* <LazyLoadComponent PlaceholderComponent={<AsideTableSkeleton height={545} />} id='ForexRealtime'>
        <ForexRealtime />
      </LazyLoadComponent> */}
      {/* 專題報導 */}
      <LazyLoadComponent PlaceholderComponent={<NewsFeatureReportSkeleton />} id='NewsFeatureReport'>
        <FeatureReportWrapper>
          <NewsFeatureReport />
        </FeatureReportWrapper>
      </LazyLoadComponent>
      <AdSlot profile={MobileAdProfiles.indexBannerWdStock} />
      {/* 投資虛擬貨幣 一次就上手 */}
      <LazyLoadComponent PlaceholderComponent={<ReactLoadingSkeletion height={640} />}>
        <InvestCrytpoMobile />
      </LazyLoadComponent>
      <AdSlot profile={MobileAdProfiles.indexBannerForex} />
      {/* 全球休市市場 */}
      <MarketClosed />
      {/* 鉅亨服務 */}
      <LazyLoadComponent PlaceholderComponent={<SkeletonAnueService />} id='AnueService'>
        <AnueService />
      </LazyLoadComponent>
    </>
  );
};

export default memo(MobileRootWrapper);
