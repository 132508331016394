'use client';

import { styled } from '@linaria/react';
import { memo } from 'react';
import LogoWallItemSkeleton from './LogoWallItemSkeleton';


const Container = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: white;
  padding: 8px 12px 0 12px;
`;

const DEFAULT_LIMIT = 4;

const LogoWallCarouselSkeleton = () => {
  return (
    <Container>
      {Array.from({ length: DEFAULT_LIMIT })
        .fill(0)
        .map((_, index) => (
          <LogoWallItemSkeleton key={`logo-item-skelton-${index}`} />
        ))}
    </Container>
  );
};

export default memo(LogoWallCarouselSkeleton);
