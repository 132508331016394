'use client';

import { RectangleSkeleton } from '@fe-common-ui/Skeleton';
import dynamic from 'next/dynamic';
import { Suspense } from 'react';

const DynamicNewsLiveCarousel = dynamic(() => import('@fe-news/components/molecules/NewsLiveCarousel/NewsLiveCarousel'), {
  loading: () => <RectangleSkeleton width={802} height={40} borderRadius={8} margin='0 0 22px 0' />,
  ssr: false
});

const DynamicLoadNewsLiveCarousel = () => {
  return (
    <Suspense>
      <DynamicNewsLiveCarousel />
    </Suspense>
  );
};

export default DynamicLoadNewsLiveCarousel;
