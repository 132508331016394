import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/app/_components/dynamic-load-navigator-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/atoms/RwdHidden/RwdHidden.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/CelebrityArea/CelebrityAreaHeader/ColumnistsCarousel/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/IndexMobileWrapper/BottomFloatIndex/BottomFloatIndex.tsx");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/IndexMobileWrapper/NewsLatest/NewsLatest.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/IndexMobileWrapper/TopNavBar/TopNavBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/MobileRootWrapper/MobileRootWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/NewsLiveCarousel/lazy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/NewsSearch/lazy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/ScrollTriggered/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/templates/DynamicLoad/MobileSearch.tsx");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/Group/TopSelector/TopSelector.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/List/theme/News.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/List/theme/NewsList.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/News/News.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/News24hSkeleton.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/NewsPopularSkeleton.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/AsideYesClubAd.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/NewsIssueSkeleton.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/NewsRecentTopic.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/NewsTwStockExpert.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/LogoWallSkeleton.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/AnueLecureSkeleton.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/NewsFeatureReport.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/SkeletonAnueService.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/LiTVSkeleton.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/NewsDetailPage/NewsDetailPage.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/ChartQSkeleton.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/LoadingSkeleton/theme/AsideTableSkeleton.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/organisms/NewsAsideDesktop/lazy.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/organisms/NewsAsideDesktop/NewsAsideDesktop.server.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/organisms/NewsAside/NewsAside.server.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/app/_layout/desktop-with-sidebar.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/MainNews/MainNews.server.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/organisms/NewsListContent/NewsListContent.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/organisms/NewsListHeader/NewsListHeader.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/templates/NewsCategory/Desktop.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/IndexMobileWrapper/PopularSearch.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/CelebrityArea/CelebrityAreaHeader/index.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/app/_layout/mobile.linaria.module.css");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/AnueService/AnueService.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/Aside/AnueLecture/AnueLecture.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/Aside/AsideWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/Aside/FundRedirect/FundRedirect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/Aside/News24h/News24h.tsx");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/Aside/NewsFeatureReport/NewsFeatureReport.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/Aside/NewsIssue/NewsIssue.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/Aside/NewsTwStockExpert/NewsTwStockExpert.tsx");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/Aside/RecentTopic/RecentTopic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/atoms/CollectionSaveButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/atoms/NavBar/NavBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/atoms/RwdAdSlot/RwdAdSlot.tsx");
;
import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/components/LazyLoadComponent/LazyLoadComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/LogoWallCarousel/LogoWallCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/AsideCnyesAd/AsideCnyesAd.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/AsideDesktopFirstAd/AsideDesktopFirstAd.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/AsideNewsPopular/AsideNewsPopularContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/AsidePopTopic/AsidePopTopicSkeleton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/AsidePopTopic/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/AsideRankingList/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/AsideSection/AsideSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/MobileBottomAdBanner/MobileBottomAdBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/NewsCarousel/lazy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/NewsTypeBadge/NewsTypeBadge.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/NewsFilter/NewsFilter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/NewsList/NewsListV2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/organisms/NewsAsideMobile/NewsAsideMobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/context/date-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/context/query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QuoteGtmEventType","default"] */ "/opt/app/libs/fe-common-ui/src/Group/TagsGroup/TagsGroup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/libs/fe-common-ui/src/Image/Card/Card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","AsideNewsGtmEventType"] */ "/opt/app/libs/fe-common-ui/src/List/theme/AsideNews.tsx");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/List/theme/CategoryNews.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/libs/fe-common-ui/src/List/theme/IndexMobileList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/libs/fe-common-ui/src/List/theme/SearchNews.tsx");
;
import(/* webpackMode: "eager" */ "/opt/app/libs/fe-common-ui/src/StickyDetector/StickyDetector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AD_EMPTY_EVENT","AD_RENDERED_EVENT","default"] */ "/opt/app/libs/fe-dfp/src/lib/adslot/adslot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/libs/fe-dfp/src/lib/dynamicAdslot/dynamicAdslot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Initialize"] */ "/opt/app/libs/fe-dfp/src/lib/initialize/initialize.tsx");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.0_react@18.3.1__react@18.3.1_sass@1.77.6/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.0_react@18.3.1__react@18.3.1_sass@1.77.6/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.0_react@18.3.1__react@18.3.1_sass@1.77.6/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.0_react@18.3.1__react@18.3.1_sass@1.77.6/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.0_react@18.3.1__react@18.3.1_sass@1.77.6/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/.pnpm/react-loading-skeleton@3.3.1_react@18.3.1/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/.pnpm/react-loading-skeleton@3.3.1_react@18.3.1/node_modules/react-loading-skeleton/dist/skeleton.css");
