'use client';

import { NewsTagGtmEventType } from '@fe-common-ui/Button';
import { QuoteGtmEventType, TagsGroup } from '@fe-common-ui/Group';
import List, { CategoryNewsListGtmEventType } from '@fe-common-ui/List';
import Skeleton from '@fe-common-ui/LoadingSkeleton';
import { GtmEventType } from '@fe-common-ui/ReadMore/ReadMore';
import CollectionSaveButton from '@fe-news/components/atoms/CollectionSaveButton';
import CardSize from '@fe-news/constants/size/card';
import { styled } from '@linaria/react';
import { useEffect } from 'react';
import { CustomEventName, GTMEventKey } from '@fe-cnyes/fe-common-gtm';
import useMediaQuery from '@fe-news/hooks/use-media-query';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';

type NewsCardProps = {
  index: number;
  data: ListNewsItem[];
  style: React.CSSProperties;
  setSize?: (index: number, size: number) => void;
};

const EmptyContainer = styled.div`
  margin-bottom: 16px;
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const NewsCard = ({
  data,
  style,
  gtmEvent,
  isPortfolios = false
}: {
  data: ListNewsItem;
  style?: React.CSSProperties;
  gtmEvent?: GtmEventType;
  isPortfolios?: boolean;
}) => {
  const isDesktop = useMediaQuery(`(min-width:${SCREEN_SIZE.DESKTOP}px)`, true);
  const { title, newsId, publishAt, categoryName, coverSrc, keyword, isCategoryHeadline, quotes } = data;
  const imgSource = coverSrc?.s?.src;

  return isDesktop ? (
    <List.CategoryNewsList
      newsId={newsId}
      title={title}
      publishAt={publishAt}
      breadcrumbs={categoryName}
      coverSrc={imgSource}
      Tags={
        <TagContainer>
          <TagsGroup
            tags={keyword}
            gtmEventNewsTagType={NewsTagGtmEventType.NEWS_LIST}
            gtmEventQuoteType={QuoteGtmEventType.NEWS_LIST}
            otherProduct={quotes}
          />
          <CollectionSaveButton
            newsId={newsId}
            gtmEvent={
              isPortfolios
                ? {
                    [GTMEventKey.EVENT_NAME]: CustomEventName.FAVORITE,
                    [GTMEventKey.SECTION]: 'News_Category_我的追蹤',
                    [GTMEventKey.CLICK_ITEM]: `自選股新聞＿${newsId}_${title}`
                  }
                : {
                    [GTMEventKey.EVENT_NAME]: CustomEventName.FAVORITE,
                    [GTMEventKey.SECTION]: 'News_Category',
                    [GTMEventKey.CLICK_ITEM]: `${newsId}_${title}`
                  }
            }
          />
        </TagContainer>
      }
      isHeadline={isCategoryHeadline}
      enableShowHeadlineMark
      gtmEventType={CategoryNewsListGtmEventType.NEWS_CATEGORY}
      style={style}
      gtmEvent={gtmEvent}
      isPortfolios={isPortfolios}
    />
  ) : (
    <List.AsideNews
      newsId={newsId}
      title={title}
      publishAt={publishAt}
      categoryName={categoryName}
      coverSrc={imgSource}
      Tags={
        <TagContainer>
          <TagsGroup
            tags={keyword}
            gtmEventNewsTagType={NewsTagGtmEventType.NEWS_LIST}
            gtmEventQuoteType={QuoteGtmEventType.NEWS_LIST}
            otherProduct={quotes}
          />
          <CollectionSaveButton
            newsId={newsId}
            gtmEvent={
              isPortfolios
                ? {
                    [GTMEventKey.EVENT_NAME]: CustomEventName.FAVORITE,
                    [GTMEventKey.SECTION]: 'News_Category_我的追蹤',
                    [GTMEventKey.CLICK_ITEM]: `自選股新聞＿${newsId}_${title}`
                  }
                : {
                    [GTMEventKey.EVENT_NAME]: CustomEventName.FAVORITE,
                    [GTMEventKey.SECTION]: 'News_Category',
                    [GTMEventKey.CLICK_ITEM]: `${newsId}_${title}`
                  }
            }
          />
        </TagContainer>
      }
      gtmEvent={gtmEvent}
      coverWidth={85}
      coverHeight={60}
    />
  );
};

const InfiniteNewsCard = ({ index, data, style, setSize }: NewsCardProps) => {
  useEffect(() => {
    setSize?.(index, CardSize.DesktopNews);
  }, [setSize]);

  if (!data || !data[index]) {
    return (
      <EmptyContainer style={style}>
        <Skeleton.NewsSkeleton />
      </EmptyContainer>
    );
  }

  return (
    <div key={`news-card-${index}`} style={style}>
      <NewsCard data={data[index]} />
    </div>
  );
};

export default InfiniteNewsCard;
