/* eslint-disable react/boolean-prop-naming */
'use client';

import { styled } from '@linaria/react';
import dynamic from 'next/dynamic';

const DynamicNewsBadge = dynamic(() => import('@fe-common-ui/Button').then(module => module.NewsLabel), {
  ssr: true
});

enum LabelTitle {
  LATEST = '最新',
  IS24H = '精選'
}

enum LabelColor {
  LATEST = '#e03f19',
  IS24H = '#54b5c6',
  POPULAR = '#ffb43e'
}

const MobileLabel = styled.span<{ color?: string }>`
  display: inline;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  line-height: 20px;
  padding: 3px 7px;
  background-color: ${({ color }) => color || '#54b5c6'};
  border-radius: 6px;
  text-align: center;
  margin-right: 8px;
`;

type NewsTypeBadgeProps = {
  is24h?: boolean;
  isMobile?: boolean;
  keyword?: string[];
};

const NewsTypeBadge = ({ is24h, isMobile, keyword }: NewsTypeBadgeProps) => {
  const label = is24h ? LabelTitle.IS24H : LabelTitle.LATEST;
  const color = is24h ? LabelColor.IS24H : LabelColor.LATEST;
  const isAdNews = keyword?.includes('趨勢分析');

  // 如果新聞是廣編稿則不顯示
  if (isAdNews) return null;

  if (isMobile) return <MobileLabel color={color}>{label}</MobileLabel>;

  return <DynamicNewsBadge color={color}>{label}</DynamicNewsBadge>;
};

export default NewsTypeBadge;
