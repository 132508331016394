/* eslint-disable @typescript-eslint/no-explicit-any */

import { getDateStart } from '@fe-common-utils/libs/time';
import { GETNewsOfMultipleCategory, GETv1AllNews } from '@fe-news/api/news';
import { fetchQuotesBySymbolsKeys } from '@fe-news/components/Aside/News24h/hooks/use-fetch-news24h';
import { REPORT_CARD_HEIGHT_CONTAINER } from '@fe-news/components/NewsList/ListItem/ReportCard';
import { REPORT_NEWS_POS } from '@fe-news/constants/ad/ad';
import { NewsCategory } from '@fe-news/constants/categories';
import { Device } from '@fe-news/constants/device';
import { NewsListItemType } from '@fe-news/constants/news/type';
import CardSize from '@fe-news/constants/size/card';
import { insertAdToNewsList } from '@fe-news/utils/news';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { formatQuotes, pagesNewsSymbols } from '@fe-news/utils/client/product';

const PRE_PAGE_COUNT = 20;
const DEFAULT_PAGE = 1;
const DEFAULT_RETURN_VALUE = {
  data: [],
  total: 0,
  currentPage: DEFAULT_PAGE,
  lastPage: DEFAULT_PAGE,
  perPage: PRE_PAGE_COUNT,
  from: DEFAULT_PAGE,
  to: DEFAULT_PAGE
};

// TODO: remove any, doc: https://tanstack.com/query/latest/docs/react/reference/useInfiniteQuery
export const getCategoryNews = async ({
  pageParam = 1,
  category,
  startAt,
  endAt,
  showOutsource,
  isCategoryHeadline
}: {
  pageParam: number;
  category?: string | string[];
  startAt?: string;
  endAt?: string;
  showOutsource?: string;
  isCategoryHeadline?: string;
}) => {
  try {
    let params: {
      categories?: string | string[];
      limit: number;
      page: number;
      startAt?: string;
      endAt?: string;
      showOutsource?: string;
      isCategoryHeadline?: string;
    } = { limit: 30, page: pageParam, startAt, endAt, isCategoryHeadline };
    if (category) params = { ...params, categories: category, showOutsource };

    const response = await GETNewsOfMultipleCategory(params);

    return {
      data: response?.items?.data || [],
      total: response?.items?.total || 0,
      currentPage: response?.items?.current_page || DEFAULT_PAGE,
      lastPage: response?.items?.last_page || DEFAULT_PAGE,
      perPage: response?.items?.per_page || PRE_PAGE_COUNT,
      from: response?.items?.from || DEFAULT_PAGE,
      to: response?.items?.to || DEFAULT_PAGE
    };
  } catch (error) {
    console.error('[API Error]: GETNewsOfMultipleCategory', error);
    return DEFAULT_RETURN_VALUE;
  }
};

export const getAllNews = async ({
  pageParam = 1,
  startAt,
  endAt
}: {
  pageParam: number;
  startAt: string;
  endAt: string;
}) => {
  try {
    const response = await GETv1AllNews({ page: pageParam, startAt, endAt });

    return {
      data: response?.items?.data || [],
      total: response?.items?.total || 0,
      currentPage: response?.items?.current_page || DEFAULT_PAGE,
      lastPage: response?.items?.last_page || DEFAULT_PAGE,
      perPage: response?.items?.per_page || PRE_PAGE_COUNT,
      from: response?.items?.from || DEFAULT_PAGE,
      to: response?.items?.to || DEFAULT_PAGE
    };
  } catch (error) {
    console.error('[API Error]: GETv1AllNews', error);
    return DEFAULT_RETURN_VALUE;
  }
};

type Props = {
  category: string;
  dates?: string[];
  isShowOutsource?: boolean;
  enableFetchQuotes?: boolean;
  isCategoryHeadline?: string;
  isShowReportNews?: boolean;
  isShowAd?: boolean;
  device: Device;
};

type QueryData = {
  pageParams: number[];
  pages: PageData<News[]>[];
};

const useFetchInfiniteListNews = ({
  category = NewsCategory.HEADLINE,
  dates = [],
  isShowOutsource = true,
  enableFetchQuotes,
  isCategoryHeadline = '1',
  isShowReportNews = true,
  isShowAd = true,
  device = Device.DESKTOP
}: Props) => {
  const [startDate, endDate] = dates || [];
  const showOutsource = isShowOutsource ? '1' : '0';

  const {
    data: queryData,
    fetchNextPage,
    isFetching
  } = useInfiniteQuery<unknown, unknown, QueryData, unknown[], number>({
    queryKey: [
      'news',
      category,
      Math.floor(Number(startDate) / 1000),
      Math.floor(Number(endDate) / 1000),
      showOutsource,
      isCategoryHeadline
    ],
    queryFn:
      category === NewsCategory.ALL
        ? ({ pageParam }) => getAllNews({ pageParam, startAt: startDate, endAt: endDate })
        : ({ pageParam }) =>
            getCategoryNews({
              pageParam,
              category,
              startAt: startDate,
              endAt: endDate,
              showOutsource,
              isCategoryHeadline
            }),
    getNextPageParam: (lastPage, pages) => pages.length + 1,
    initialPageParam: 1,
    notifyOnChangeProps: ['data']
  });

  const pages = queryData?.pages || [];
  const data = pages?.map(page => page.data || []).flat() || [];
  const total = pages?.[0]?.total;
  const symbols = pagesNewsSymbols(pages[0]?.data);

  // 取得前30篇新聞股票漲跌資訊(另外call API)
  const { data: quotes } = useQuery({
    queryKey: [...symbols],
    queryFn: () => fetchQuotesBySymbolsKeys([...symbols]),
    enabled: enableFetchQuotes && pages.length <= 1,
    notifyOnChangeProps: ['data'],
    select: formatQuotes
  });

  const isItemLoaded = (index: number) => index < data.length && data[index] !== null;

  const loadMoreItems = () => {
    fetchNextPage();
  };

  // 將整理好的quotes格式塞回news[]
  // 在每個news中塞入type,方便之後用不同listItem顯示
  const quoteData =
    (quotes &&
      enableFetchQuotes &&
      data.map(news => {
        const markets = news.market;

        const formatQuote = markets?.map(({ symbol }) => quotes?.[symbol])?.filter(v => v) || null;

        return {
          ...news,
          quotes: formatQuote,
          type: NewsListItemType.NEWS
        };
      })) ||
    data;

  const publishAts = quoteData
    .map(news => getDateStart(news.publishAt))
    .filter(timestamp => timestamp !== getDateStart(Math.floor(Date.now() / 1000)));

  const dayStartTimestamp = new Set(publishAts);

  const insertDates = [...dayStartTimestamp].map(t => ({
    type: NewsListItemType.DATE,
    publishAt: t + 24 * 60 * 60 - 1,
    height: CardSize.DesktopDate
  }));

  const newsWithDate = [...quoteData, ...insertDates] as ListNewsItem[];
  newsWithDate.sort((a, b) => b.publishAt - a.publishAt);

  /**
   * 插入廣告
   * Desktop: 每五則新聞插入一則廣告
   * Mobile: 插在2,5,8,13,18則後
   */
  const newsWithDateAndAd = isShowAd ? insertAdToNewsList(newsWithDate, device) : newsWithDate;

  // 在第8則插入專題報導
  if (isShowReportNews) {
    const reportNews = { type: NewsListItemType.REPORT, height: REPORT_CARD_HEIGHT_CONTAINER } as ListNewsItem;
    newsWithDateAndAd.splice(REPORT_NEWS_POS, 0, reportNews);
  }

  return {
    data: newsWithDateAndAd,
    isItemLoaded,
    loadMoreItems,
    total: total > newsWithDateAndAd.length ? total : newsWithDateAndAd.length,
    isFetching
  };
};

export default useFetchInfiniteListNews;
