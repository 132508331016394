'use client';

import { styled } from '@linaria/react';
import { ReactNode } from 'react';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import useMediaQuery from '@fe-news/hooks/use-media-query';

// 在useMediaQuery讀取前還是會render瞬間，所以需要用css先隱藏

const Desktop = styled.div`
  display: block;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: none;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    display: block;
  }
`;

interface RwdHiddenProps {
  isDesktop?: boolean;
  isMobile?: boolean;
  children?: ReactNode;
}

const RwdHidden = ({ isDesktop = false, isMobile = false, children }: RwdHiddenProps) => {
  const isShowMobile = useMediaQuery(`(max-width:${SCREEN_SIZE.DESKTOP}px)`, isMobile);
  const isShowDesktop = useMediaQuery(`(min-width:${SCREEN_SIZE.DESKTOP}px)`, isDesktop);
  return (
    <>
      {isShowMobile && !isDesktop && <Mobile>{children}</Mobile>}
      {isShowDesktop && !isMobile && <Desktop>{children}</Desktop>}
    </>
  );
};

export default RwdHidden;
