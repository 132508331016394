import { CoverImageTypeRecord } from '../cover';

export enum NewsListItemType {
  NEWS = 'news',
  DATE = 'date',
  AD = 'ad',
  AD_MOBILE_CATEGORY = 'ad-mobile-category',
  REPORT = 'report',
  CeleBrityArea = 'celebrity-area'
}

// 主編精選

export type GETNews24hListResponse = {
  items: PageData<NewsCategoryType[]>;
  message: string;
  statusCode: number;
};

// // 專題報導

export type ProjectType = {
  id: number;
  title: string;
  link: string;
  categoryId: number;
  createdAt: number;
  image: CoverImageTypeRecord<'s' | 'm' | 'l' | 'xl'>;
};

export type GETNewsCategoriesResponse = {
  statusCode: number;
  message: string;
  items: {
    project: ProjectType[];
  };
};

// 熱門話題

export type TagCloudData = {
  avgPageview: string;
  date: string;
  kind: string;
  rank: number;
  tag: string;
  url: string;
};

export type GETTagCloudResponse = {
  items: TagCloudData[];
  message: string;
  statusCode: number;
};

export type GETNewsListByTagsResponse = {
  items: PageData<NewsListByTagsType[]>;
  message: string;
  statusCode: number;
};

type NewsListByTagsType = {
  newsId: number;
  title: string;
  content: string;
  keywords: string[];
  publishAt: number;
  payment: number;
  titleWithKwd: number;
  contentWithKwd: number;
  hasCoverPhoto: number;
  coverSrc: CoverImageTypeRecord<'s' | 'm' | 'l' | 'xl' | 'xxl' | 'xs'> | null;
};

// 下單中心

export type GETLogoWallByTypeResponse = {
  items: LogoWallProps[];
  message: string;
  statusCode: number;
};

// 議題新聞

export type GETNewsCategoryResponse = {
  items: PageData<IssueNewsDataType[]>;
  message: string;
  statusCode: number;
};

export type IssueNewsDataType = {
  newsId: number;
  url: string;
  time: string;
  title: string;
  src: string;
  publishAt: number;
  coverSrc: CoverType;
  categoryName: string;
  topic: {
    topicId?: number;
    title?: string;
  };
  tags: unknown;
  otherProduct: unknown;
};

// 鉅亨講座

export type GETNotificationResponse = {
  items: { data: NotificationLecturesDataType[] };
  message: string;
  statusCode: number;
};

export type LectureNotification = {
  id: number;
  title: string;
  highlight: number;
  link: string;
  year: string;
  mouth: string;
  date: string;
  day: string;
  time: string;
};
