'use client';

import dynamic from 'next/dynamic';
import { Suspense } from 'react';

const DynamicNewsCarousel = dynamic(() => import('./NewsCarousel'), {
  ssr: false
});

type DynamicLoadNewsCarouselProps = {
  news: News[];
};

const DynamicLoadNewsCarousel = ({ news }: DynamicLoadNewsCarouselProps) => {
  return (
    <Suspense>
      <DynamicNewsCarousel news={news} />
    </Suspense>
  );
};

export default DynamicLoadNewsCarousel;
