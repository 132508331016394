// import { uuidv4 } from '@fe-common-utils/libs/uuid';
import { styled } from '@linaria/react';
import { memo } from 'react';
import NewsListItemSkeleton from '../NewsListItem/NewsListItemSkeleton';

const View = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type NewsListSkeletonProps = {
  itemHeight?: number | undefined;
  isHaveImage?: boolean;
};

const DEFAULT_COUNT = 20;

const skeletons: number[] = Array.from({ length: DEFAULT_COUNT }).fill(0) as number[];

const NewsListSkeleton = ({ itemHeight }: NewsListSkeletonProps) => {
  return (
    <View>
      {skeletons.map((_, index) => (
        <NewsListItemSkeleton
          key={`news_list_item_skeleton_${(index + 1)}`}
          height={itemHeight}
          isHaveImage={false}
        />
      ))}
    </View>
  );
};

export default memo(NewsListSkeleton);
