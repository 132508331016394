'use client';
import { CardType } from '@fe-news/components/Aside/NewsFeatureReport/FeatureReportCardToggle';
import NewsFeatureReport from '@fe-news/components/Aside/NewsFeatureReport/NewsFeatureReport';
import { styled } from '@linaria/react';
import { useEffect } from 'react';

export const REPORT_CARD_HEIGHT_CONTAINER = 266;
const CARD_PADDING = 24;

type ReportCardProps = {
  data: ListNewsItem[] | CelebrityAreaDataWithType[];
  index: number;
  style: React.CSSProperties;
  setSize?: (index: number, size: number) => void;
};

const View = styled.div`
  .scroll-wrapper {
    padding: 0 !important;
    margin: 8px 0 24px 0 !important;
    border-bottom: 1px solid #e2e8f1;
  }
`;

export const ReportCard = ({ style }: { style: React.CSSProperties }) => {
  return (
    <NewsFeatureReport
      isOnlyCarousel
      isScroll
      cardHeight={REPORT_CARD_HEIGHT_CONTAINER - CARD_PADDING}
      type={CardType.INDEX_LIST}
      style={style}
    />
  );
};

const InfiniteReportCard = ({ index, style, setSize }: ReportCardProps) => {
  useEffect(() => {
    setSize?.(index, REPORT_CARD_HEIGHT_CONTAINER);
  }, [index, setSize]);

  return (
    <View key={`date-card-${index}`} style={style}>
      <NewsFeatureReport
        isOnlyCarousel
        isScroll
        cardHeight={REPORT_CARD_HEIGHT_CONTAINER - CARD_PADDING}
        type={CardType.INDEX_LIST}
      />
    </View>
  );
};

export default InfiniteReportCard;
