'use client';

import { NewsTagGtmEventType } from '@fe-common-ui/Button';
import { TagsGroup } from '@fe-common-ui/Group';
import List from '@fe-common-ui/List';
import { styled } from '@linaria/react';
import { useEffect, useRef } from 'react';
import CollectionSaveButton from '@fe-news/components/atoms/CollectionSaveButton';
import Skeleton from '@fe-common-ui/LoadingSkeleton';
import { CustomEventName, GTMEventKey } from '@fe-cnyes/fe-common-gtm';

const PADDING_BOTTOM = 16;

const View = styled.div`
  .search-content-header {
    margin-bottom: 10px;
  }

  .search-news-container {
    height: auto;
  }
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const EmptyContainer = styled.div`
  margin-bottom: 16px;
`;

type MyTagNewsCardProps = {
  data: ListNewsItem;
  index: number;
  style: React.CSSProperties;
  tag: string;
  setSize?: (index: number, size: number) => void;
};

const MyTagNewsCard = ({ data, index, style, tag, setSize }: MyTagNewsCardProps) => {
  const listReference = useRef<HTMLLIElement>(null);

  const { newsId, title, publishAt, keyword, content, category } = data;

  const breadcrumb = category?.[0]?.name ?? '';

  useEffect(() => {
    if (setSize && listReference.current) {
      const newItemHeight = listReference.current?.getBoundingClientRect()?.height + PADDING_BOTTOM;

      setSize(index, newItemHeight);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) {
    return (
      <EmptyContainer style={style}>
        <Skeleton.NewsSkeleton />
      </EmptyContainer>
    );
  }

  return (
    <View key={newsId} style={style}>
      <List.SearchNews
        ref={listReference}
        newsId={newsId}
        title={title}
        publishAt={publishAt}
        breadcrumbs={breadcrumb}
        gtmEvent={{
          [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_NEWS,
          [GTMEventKey.SECTION]: 'News_Category_我的追蹤',
          [GTMEventKey.CLICK_ITEM]: `追蹤的標籤＿${newsId}_${title}`
        }}
        Tags={
          keyword && (
            <TagContainer>
              <TagsGroup focusTag={tag} tags={keyword} gtmEventNewsTagType={NewsTagGtmEventType.NEWS_SEARCH_NEWS_TAG} />
              <CollectionSaveButton
                newsId={newsId}
                gtmEvent={{
                  [GTMEventKey.EVENT_NAME]: CustomEventName.FAVORITE,
                  [GTMEventKey.SECTION]: 'News_Category_我的追蹤',
                  [GTMEventKey.CLICK_ITEM]: `追蹤的標籤＿${newsId}_${title}`
                }}
              />
            </TagContainer>
          )
        }
        summary={content}
      />
    </View>
  );
};

export default MyTagNewsCard;
