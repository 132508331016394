'use client';

import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { styled } from '@linaria/react';
import type { FC } from 'react';

type CardProps = {
  newsId: number;
  title: string;
  src: string;
  href: string;
  width?: number;
  height?: number;
  bottom?: number;
  padding?: number;
  fontSize?: number;
  Badge?: React.ReactNode;
  gtmEvent?: {
    [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_NEWS;
    [GTMEventKey.SECTION]: string;
    [GTMEventKey.CLICK_ITEM]: string;
  };
};

const Container = styled.a`
  position: relative;
  display: block;
`;

const TitleContainer = styled.div<{
  bottom?: number;
  padding?: number;
  fontSize?: number;
}>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 16)}px;
  color: white;
  position: absolute;
  bottom: ${({ bottom }) => (bottom ? bottom : 16)}px;
  padding: 0 ${({ padding }) => (padding ? padding : 8)}px;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.h3`
  font-weight: 500;
  line-height: 24px;
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ImageContainer = styled.div<{
  src: string;
  width: number;
  height: number;
}>`
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  background-color: #e2e8f1;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 40%;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    background-image: linear-gradient(rgba(49, 62, 79, 0), rgba(22, 29, 39, 0.8));
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${({ src }) => `url(${src})` ?? ''};
    background-image: '';
    background-size: cover;
    background-position: center;
    transition: 0.4s;
  }

  :hover {
    &::before {
      transform: scale(1.1);
    }
  }
`;

const Card: FC<CardProps> = ({
  newsId,
  title,
  src,
  href,
  width = 242,
  height = 159,
  bottom,
  padding,
  fontSize,
  Badge,
  gtmEvent
}) => {
  const onClick = () => {
    if (gtmEvent) {
      sendGTM(gtmEvent);
    }
  };

  const defaultImageSrc = `/assets/images/news_default_images/${newsId % 10}.webp`;
  const imageSource = src || defaultImageSrc;

  return (
    <Container href={href} onClick={onClick}>
      <TitleContainer bottom={bottom} padding={padding} fontSize={fontSize}>
        {Badge && Badge} <Title>{title}</Title>
      </TitleContainer>
      <ImageContainer src={imageSource} width={width} height={height} />
    </Container>
  );
};

export default Card;
