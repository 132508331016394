'use client';

import { DesktopAdCard } from '@fe-news/components/NewsList/ListItem/DesktopAdCardV2';
import DateCard from '@fe-news/components/NewsList/ListItem/DateCard';
import { NewsCard } from '@fe-news/components/NewsList/ListItem/NewsCard';
import { NewsCategory } from '@fe-news/constants/categories';
import { Device } from '@fe-news/constants/device';
import { NewsListItemType } from '@fe-news/constants/news/type';
import useFetchInfiniteListNews from '@fe-news/hooks/fetchInfiniteList/use-fetch-infinite-list-news';
import { useDateContext } from '@fe-news/hooks/use-date-context';
import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import { FC, useCallback, useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import dynamic from 'next/dynamic';
import MyTagNewsCard from '@fe-news/components/atoms/MyTagContent/MyTagNewsCard';
import NewsSearchListSkeleton from '@fe-news/components/molecules/NewsSearchList/NewsSearchListSkeleton';
import { CustomEventName, GTMEventKey } from '@fe-cnyes/fe-common-gtm';

const ReportCard = dynamic(
  () => import('@fe-news/components/NewsList/ListItem/ReportCard').then(module => module.ReportCard),
  { ssr: false }
);

const MILLISECONDS = 1000;

export enum InfiniteListType {
  NEWS_SEARCH_LIST = 'newsSearchList',
  NEWS_TAG_LIST = 'newsTagList'
}

interface InfiniteListProps {
  data: ListNewsItem[];
  loadMoreItems: () => void;
  listType?: InfiniteListType;
  isHeadline?: boolean;
  isTag?: boolean;
  keyword?: string;
  total?: number;
  isLoading?: boolean;
  isPortfolios?: boolean;
}

const Container = styled.div`
  width: 100%;
  min-height: 800px;
  background: white;
`;

let defalutAdsId = [0, 1, 2, 3, 4, 5];

const RenderCard = ({
  data,
  isTag = false,
  keyword = '',
  isPortfolios
}: {
  data: ListNewsItem[];
  isTag?: boolean;
  keyword?: string;
  isPortfolios: boolean;
}) => {
  const [visibleAds, setVisibleAds] = useState<number[]>(defalutAdsId);
  const [currentVisibleAd, setCurrentVisibleAd] = useState<number>(0);

  const dataWithVisibleAds = data.filter(item => {
    if (item.type !== NewsListItemType.AD) {
      return true;
    }
    if (typeof item.id === 'number' && visibleAds.includes(item.id)) {
      return true;
    }
    return false;
  });

  const onChangeCurrentVisibleAd = useCallback((v: number) => {
    setCurrentVisibleAd(v);
  }, []);

  const onChangeVisibleAds = useCallback((v: number[]) => {
    setVisibleAds(v);
    defalutAdsId = v;
  }, []);

  useEffect(() => {
    onChangeVisibleAds(
      currentVisibleAd > 3
        ? [
            currentVisibleAd - 3,
            currentVisibleAd - 2,
            currentVisibleAd - 1,
            currentVisibleAd,
            currentVisibleAd + 1,
            currentVisibleAd + 2
          ]
        : [0, 1, 2, 3, 4, 5]
    );
  }, [currentVisibleAd]);

  return data.map((item: ListNewsItem, index: number) => {
    const type = item.type;

    // 換日時插入日期
    if (type === NewsListItemType.DATE) {
      return <DateCard key={index} {...item} datetime={item?.publishAt} style={{ marginTop: 16 }} />;
    }

    // 每5則新聞插入廣告
    if (type === NewsListItemType.AD) {
      return (
        <DesktopAdCard
          visibleAds={visibleAds}
          onChangeCurrentVisibleAd={onChangeCurrentVisibleAd}
          index={item?.id || 0}
          key={`ad-card-${index}`}
          style={{ marginTop: 16 }}
        />
      );
    }

    // 第8則插入專題報導
    if (type === NewsListItemType.REPORT) {
      return <ReportCard key={`report-card-${index}`} style={{ borderBottom: '1px solid #e2e8f1' }} />;
    }

    return isTag ? (
      <MyTagNewsCard
        data={item}
        index={index}
        tag={keyword ?? ''}
        key={index}
        style={{ marginTop: 16, marginBottom: 16 }}
      />
    ) : (
      <NewsCard
        data={item}
        key={index}
        style={{ marginTop: 16, marginBottom: 16 }}
        isPortfolios={isPortfolios}
        gtmEvent={
          isPortfolios
            ? {
                [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_NEWS,
                [GTMEventKey.SECTION]: 'News_Category_我的追蹤',
                [GTMEventKey.CLICK_ITEM]: `自選股新聞＿${item.newsId}_${item.title}`
              }
            : undefined
        }
      />
    );
  });
};

export const InfiniteList: FC<InfiniteListProps> = ({
  data,
  loadMoreItems,
  isTag,
  keyword,
  total,
  isPortfolios = false,
  isLoading = false
}) => {
  return (
    <Container>
      <InfiniteScroll
        dataLength={data.length}
        next={loadMoreItems}
        hasMore={data.length < (total ?? 0)}
        loader={<NewsSearchListSkeleton />}
        endMessage={isLoading ? <NewsSearchListSkeleton /> : <div>沒有更多新聞了</div>}
        style={{ overflow: 'visible' }}
        scrollThreshold={0.3}
      >
        <RenderCard data={data} isTag={isTag} keyword={keyword} isPortfolios={isPortfolios} />
      </InfiniteScroll>
    </Container>
  );
};

interface NewsListProps {
  category: NewsCategory;
}

const NewsList = ({ category = NewsCategory.HEADLINE }: NewsListProps) => {
  const { dates } = useDateContext();
  const formatDates = dates?.map((d: Date) => Math.floor(d.getTime() / MILLISECONDS).toString());

  const { data: isCategoryHeadline } = useQuery<unknown, unknown, string>({
    queryKey: ['news', 'filter', 'isCategoryHeadline'],
    initialData: category === NewsCategory.HEADLINE ? '1' : '0'
  });

  const { data, loadMoreItems, total, isFetching } = useFetchInfiniteListNews({
    category,
    dates: formatDates,
    isShowOutsource: false,
    enableFetchQuotes: true,
    isCategoryHeadline,
    device: Device.DESKTOP
  });

  return <InfiniteList data={data} loadMoreItems={loadMoreItems} total={total} isLoading={isFetching} />;
};

export default NewsList;
