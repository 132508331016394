import { AD_PER_NEWS } from '@fe-news/constants/ad/ad';
import { Device } from '@fe-news/constants/device';
import { NewsListItemType } from '@fe-news/constants/news/type';
import CardSize from '@fe-news/constants/size/card';

const AD_POS = [5, 8, 13, 18];

/**
 * @param news ListNewsItem[]
 * @param device 'desktop' | 'mobile'
 * @description 新聞插入廣告邏輯
 *              Desktop: 每五則新聞插入一則廣告
 *              Mobile: 插在2(300x250),5(native),8(native),13(native),18(native)則後
 * @returns ListNewsItem[]
 */
export const insertAdToNewsList = (news: ListNewsItem[], device: Device) => {
  let newsCount = 0;

  if (device === Device.MOBILE) {
    const mobileAd = { type: NewsListItemType.AD_MOBILE_CATEGORY, height: 282, device } as ListNewsItem;

    const adNews = news.reduce((results, news, index) => {
      const isDate = news.type === NewsListItemType.DATE; // 避免是Card是日期,newsCount還是一樣導致重複插入廣告

      if (news?.newsId) newsCount += 1;

      results.push(news);

      // 新聞第二則後插入300x250廣告
      if (newsCount === 2 && !isDate) {
        results.push(mobileAd);
      }

      if (AD_POS.includes(newsCount) && !isDate) {
        // 新聞第5,8,13,18則後插入原生廣告
        const mobileNativeAd = {
          type: NewsListItemType.AD,
          height: 126,
          device,
          pos: AD_POS.indexOf(newsCount)
        } as ListNewsItem;

        results.push(mobileNativeAd);
      }

      return results;
    }, [] as ListNewsItem[]);

    return adNews;
  }

  return news.reduce((results, news, index) => {
    const isDate = news.type === NewsListItemType.DATE; // 避免是Card是日期,newsCount還是一樣導致重複插入廣告

    if (news?.newsId) newsCount += 1;

    results.push(news);

    if (newsCount % AD_PER_NEWS === 0 && !isDate) {
      const desktopAd = {
        id: newsCount / AD_PER_NEWS - 1,
        type: NewsListItemType.AD,
        height: CardSize.DesktopNews,
        device
      } as ListNewsItem;
      results.push(desktopAd);
    }
    return results;
  }, [] as ListNewsItem[]);
};

/**
 * @todo https://gitlab.cnyes.cool/anue/frontend/fe-cnyes/-/blob/7e1a68a871e01190f6b58b82873af279ca850999/apps/fe-news/components/NewsDetail/RelatedWarrants/RelatedWarrants.tsx
 * 第34行到第36行,如果未來合併這個分支，請記得把這個檔案的第34行到36行的程式碼刪掉,並且用這個檔案的程式碼取代
 */
export const removeLastColonAndSubstring = (products: News['otherProduct']): News['otherProduct'] => {
  if (!products) return [];
  return products.map(product => product.slice(0, product.lastIndexOf(':')));
};
