'use client';

import { CustomEventName, GTMConstants, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { TrendChart } from '@fe-common-ui/TrendChart';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { useIsInteractingObserver } from '@fe-common-utils/libs/hooks/useIsInteractingObserver';
import LinkFactory from '@fe-common-utils/libs/link';
import { isClient } from '@fe-common-utils/libs/utils';
import AsideWrapper from '@fe-news/components/Aside/AsideWrapper';
import { NewsCategory } from '@fe-news/constants/categories';
import { getCategory } from '@fe-news/utils/category';
import { styled } from '@linaria/react';
import cx from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';

type TabType = { name: string; type: string; symbols: string[] };

const US_STOCK_TAB = 'UsStock';
const CN_STOCK_TAB = 'HkStocks';
const GTM_EVENT_IMP_PLACEMENT = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.IMP_PLACEMENT,
  [GTMEventKey.SECTION]: `${GTMConstants.SECTION_PLACEHOLDER}_ChartQ`
};
const OBSERVER_OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 1
};

const ChartQWrapper = styled.div`
  .chartQ-container {
    margin-bottom: 32px !important;
  }
`;

const View = styled.div`
  outline: 1px solid #e2e8f1;
  border-radius: 6px;
  overflow: hidden;
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;

  :global(.chartQ-container) {
    .section-aside-wrapper {
      padding-bottom: 24px;
    }
  }
`;

const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 49px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #515c69;
  background-color: #fff;
  position: relative;

  &.selected {
    font-weight: 500;
    color: #e03f19;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 20px;
      height: 2px;
      opacity: 0.9;
      box-shadow: 0 2px 6px 0 rgba(224, 63, 25, 0.5);
      background-color: #e03f19;
      z-index: 2;
    }
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 8px 8px 8px;
  background-color: white;

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 8px 24px;
  }
`;

const defaultTab = 'TwStocks';

const getChartWidth = () => {
  if (isClient && window?.innerWidth) {
    const childElementPadding = window?.innerWidth < SCREEN_SIZE.MOBILE ? 8 : 16;
    const paddingContainer = childElementPadding * 6;
    return Math.floor((window.innerWidth - paddingContainer) / 2);
  }
  return 0;
};

const Tabs: TabType[] = [
  {
    name: '台股',
    type: 'TwStocks',
    symbols: ['TWS:TSE01:INDEX', 'TWS:OTC01:INDEX', 'TWF:TXF:FUTURES', 'GF:TWNCON:FUTURES']
  },
  {
    name: '美股',
    type: 'UsStock',
    symbols: ['GI:DJI:INDEX', 'GI:IXIC:INDEX', 'GI:INX:INDEX', 'GI:SOX:INDEX']
  },
  {
    name: '陸港股',
    type: 'HkStocks',
    symbols: ['GI:HSI:INDEX', 'GI:HSTECH:INDEX', 'GI:SSEC:INDEX', 'GI:SZI:INDEX']
  }
];

const ChartQ = ({ category }: { category?: NewsCategory }) => {
  const [select, setSelect] = useState<string>(defaultTab);
  const categoryItem = getCategory(category as NewsCategory);
  const isUsStock = categoryItem?.category === NewsCategory.WD_STOCK;
  const isCnStock = categoryItem?.category === NewsCategory.CN_STOCK;
  const countFirstEnterChartQ = useRef(0);
  const onEnterChartQ = useCallback(() => {
    if (!countFirstEnterChartQ.current) {
      countFirstEnterChartQ.current = 1;
      sendGTM(GTM_EVENT_IMP_PLACEMENT);
    }
  }, []);
  const chartQReference = useIsInteractingObserver(onEnterChartQ, OBSERVER_OPTIONS);

  const onSelect = (type: string, name: string) => () => {
    sendGTM({
      [GTMEventKey.SECTION]: `${GTMConstants.SECTION_PLACEHOLDER}_ChartQ`,
      [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TAB,
      [GTMEventKey.CLICK_ITEM]: name
    });
    setSelect(type);
  };

  useEffect(() => {
    if (isUsStock) {
      setSelect(US_STOCK_TAB);
    }

    if (isCnStock) {
      setSelect(CN_STOCK_TAB);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChartQWrapper ref={chartQReference}>
      <AsideWrapper
        title='ChartQ'
        readMoreLink={`${LinkFactory.wwwChannelLink}/stockQ/chartQ`}
        readMoreTarget='_self'
        gtmEvent={{
          [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_BUTTON,
          [GTMEventKey.SECTION]: `${GTMConstants.SECTION_PLACEHOLDER}_ChartQ`,
          [GTMEventKey.CLICK_ITEM]: '看更多'
        }}
        className='chartQ-container'
      >
        <View>
          <TabsWrapper>
            {Tabs.map(({ name, type }) => {
              return (
                <Tab key={type} className={cx({ selected: select === type })} onClick={onSelect(type, name)}>
                  {name}
                </Tab>
              );
            })}
          </TabsWrapper>
          <ChartWrapper>
            {Tabs.find(tab => tab.type === select)?.symbols?.map(symbol => {
              return (
                <TrendChart
                  key={symbol}
                  symbol={symbol}
                  type='custom'
                  showUpdateTime
                  customChartSize={{ h: 54, w: getChartWidth() }}
                />
              );
            })}
          </ChartWrapper>
        </View>
      </AsideWrapper>
    </ChartQWrapper>
  );
};

export default ChartQ;
